import { PageProps } from "gatsby";

import { ProductType } from "../../../models";
import { SEO } from "../../components/SEO";
import { FufiInsuranceDetail } from "../../components/ZivotniPojisteni/FufiPage/Root";
import { ContractIDProvider } from "../../context/ContractIDContext";
import { ContractTypeProvider } from "../../context/ContractTypeContext";
import withProtection from "../../highOrderComponents/withProtection";

const FufiInsuranceDetailPage = (pageProps: PageProps): JSX.Element => {
	return (
		<ContractIDProvider pageProps={pageProps}>
			<ContractTypeProvider contractType={ProductType.Clf}>
				<FufiInsuranceDetail />
			</ContractTypeProvider>
		</ContractIDProvider>
	);
};

export default withProtection(FufiInsuranceDetailPage);

export const Head = (): JSX.Element => (
	<SEO title="Fufi Životko">
		<meta name="robots" content="noindex" />
	</SEO>
);
